import React from 'react';
import { Link, graphql } from 'gatsby';
import Layout from '../layout';
import SEO from '../seo';
import resourcesMainIcon from '../../images/resources-main-icon.svg';
import HeroBannerSubpage from '../HeroBannerSubpage';
import styles from "./resources-content.module.scss";

export default function ResourcesContent({
  data,
}) {
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout page="resources">
      <HeroBannerSubpage
        title={frontmatter.title}
        subtitle={frontmatter.subtitle}
        image={resourcesMainIcon}
      />
      <SEO title={frontmatter.title} subtitle={frontmatter.subtitle} />
      <div className={styles.wrapper}>
        <nav className={styles.col1}>
          <Link to="/resources/" className={styles.mainLink}>Introduction</Link>
          <p className={styles.navigationHeader}>How to start</p>
          <div className={styles.navigationSection}>
            <Link to="/resources/interface" className={styles.link}>How does Entracker interface look like?</Link>
            <Link to="/resources/permissions-and-roles" className={styles.link}>What are the permissions and roles in Entracker?</Link>
            <Link to="/resources/project-reports" className={styles.link}>What is included in Project Reports?</Link>
            <Link to="/resources/timetracking" className={styles.link}>Using timetracking in Entracker</Link>
            <Link to="/resources/sick-leaves-and-holidays" className={styles.link}>Managing sick leaves and holidays</Link>
            <Link to="/resources/default-settings" className={styles.link}>How can I adjust default settings?</Link>
            <Link to="/resources/drawing-revisions" className={styles.link}>How drawing revisions are managed by Entracker?</Link>
            <Link to="/resources/ending-trial" className={styles.link}>My trial is ending - what should I do?</Link>
          </div>
          <p className={styles.navigationHeader}>Projects and drawings management</p>
          <div className={styles.navigationSection}>
            <Link to="/resources/project-management" className={styles.link}>How does project management work in Entracker?</Link>
            <Link to="/resources/submittals" className={styles.link}>Managing submittals</Link>
            <Link to="/resources/drawing-details-and-packages" className={styles.link}>Downloading and checking drawing details</Link>
            <Link to="/resources/adding-rfis" className={styles.link}>Adding RFIs and tracking responses</Link>
            <Link to="/resources/managing-team-and-collaborators" className={styles.link}>Managing team and collaborators</Link>
            <Link to="/resources/how-to-add-remarks" className={styles.link}>How to add remarks</Link>
          </div>
        </nav>
        <div
          className={styles.col2}
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
        subtitle
      }
    }
  }
`
